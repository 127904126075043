<template>
  <div class="main" data-title="个人中心" v-title>
    <el-card class="main-content" shadow="hover">
      <div class="menu-box">
        <left-menu class="left-menu"></left-menu>
        <div class="right-main">
          <div class="head">
            <div class="head-title">个人信息</div>
          </div>

          <div class="body">
            <div class="form-main">
              <el-form
                :label-position="style"
                :label-width="width"
                :model="form"
                :rules="rules"
                class="form"
                ref="form"
              >
                <el-form-item label="姓名" prop="realname">
                  <span class="p-20" v-if="type=='view'">{{form.realname}}</span>
                  <el-input
                    placeholder="请输入"
                    suffix-icon="el-icon-edit"
                    v-else
                    v-model="form.realname"
                  ></el-input>
                </el-form-item>

                <el-form-item label="手机" prop="mobile">
                  <span class="p-20" v-if="type=='view'">{{form.mobile}}</span>
                  <el-input
                    placeholder="正在使用的手机号码"
                    suffix-icon="el-icon-mobile-phone"
                    v-else
                    v-model="form.mobile"
                  >
                    <template slot="prepend">+86</template>
                  </el-input>
                </el-form-item>

                <el-form-item label="工号" v-if="type=='view'">
                  <span class="p-20">{{form.code}}</span>
                </el-form-item>

                <el-form-item label="身份证" prop="idcard">
                  <span class="p-20" v-if="type=='view'">{{form.idcard}}</span>
                  <el-input
                    placeholder="请输入"
                    suffix-icon="el-icon-postcard"
                    v-else
                    v-model="form.idcard"
                  ></el-input>
                </el-form-item>

                <el-form-item label="部门" v-if="type=='view'">
                  <span class="p-20">{{form.department_name}}</span>
                </el-form-item>

                <el-form-item label="职务" v-if="type=='view'">
                  <span class="p-20">{{form.role_name}}</span>
                </el-form-item>

                <el-form-item label="性别">
                  <span class="p-20" v-if="type=='view'">{{form.sex == '1' ? '男' : '女'}}</span>
                  <span v-else>
                    <el-radio label="1" v-model="form.sex">男</el-radio>
                    <el-radio label="2" v-model="form.sex">女</el-radio>
                  </span>
                </el-form-item>

                <el-form-item label="籍贯">
                  <span class="p-20" v-if="type=='view'">{{getCityText(form.native_place)}}</span>
                  <el-cascader
                    :options="options"
                    class="w100"
                    v-else
                    v-model="form.tmp_native_place"
                  ></el-cascader>
                </el-form-item>

                <el-form-item label="入职日期">
                  <span class="p-20" v-if="type=='view'">{{form.join_date}}</span>
                  <el-date-picker
                    @change="$forceUpdate()"
                    placeholder="请选择"
                    suffix-icon="el-icon-date"
                    type="date"
                    v-else
                    v-model="form.join_date"
                    value-format="yyyy-MM-dd"
                  ></el-date-picker>
                </el-form-item>

                <el-form-item class="p-20" v-if="type=='view'">
                  <el-button @click="type = 'edit'" type="primary">编辑</el-button>
                </el-form-item>

                <el-form-item v-else>
                  <el-button @click="update('form')" type="primary">更新个人信息</el-button>
                  <el-button @click="type = 'view'">取消</el-button>
                </el-form-item>
              </el-form>
            </div>
            <div class="form-avatar">
              <div class="label">头像</div>
              <el-avatar :src="form.avatar" class="avatar" icon="el-icon-user-solid"></el-avatar>
              <div class="btn">
                <el-upload
                  :data="myData"
                  :headers="myHeaders"
                  :on-success="uploadSuccess"
                  :show-file-list="false"
                  action="https://api.qk.zhongheinfo.com/index/upload"
                  name="file"
                >
                  <el-button icon="el-icon-upload2" plain>更新头像</el-button>
                </el-upload>
              </div>
            </div>
          </div>
        </div>
      </div>
    </el-card>
  </div>
</template>
<script>
import LeftMenu from '@/components/LeftMenu.vue'
import {
  provinceAndCityData,
  CodeToText,
  TextToCode
} from 'element-china-area-data'
import { roleByID } from '@/unit/unit.js'
export default {
  components: {
    LeftMenu
  },
  data() {
    var checkMobile = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('手机号码不能为空'))
      } else {
        const reg = /^1\d{10}$/
        if (reg.test(value)) {
          callback()
        } else {
          return callback(new Error('请输入正确的手机号码'))
        }
      }
    }

    var checkIdCard = (rule, id, callback) => {
      if (id == '') {
        callback()
      }

      var format = /^(([1][1-5])|([2][1-3])|([3][1-7])|([4][1-6])|([5][0-4])|([6][1-5])|([7][1])|([8][1-2]))\d{4}(([1][9]\d{2})|([2]\d{3}))(([0][1-9])|([1][0-2]))(([0][1-9])|([1-2][0-9])|([3][0-1]))\d{3}[0-9xX]$/
      //号码规则校验
      if (!format.test(id)) {
        return callback(new Error('身份证号码不合规'))
      }
      //区位码校验
      //出生年月日校验   前正则限制起始年份为1900;
      var year = id.substr(6, 4), //身份证年
        month = id.substr(10, 2), //身份证月
        date = id.substr(12, 2), //身份证日
        time = Date.parse(month + '-' + date + '-' + year), //身份证日期时间戳date
        now_time = Date.parse(new Date()), //当前时间戳
        dates = new Date(year, month, 0).getDate() //身份证当月天数
      if (time > now_time || date > dates) {
        return callback(new Error('出生日期不合规'))
      }
      //校验码判断
      var c = new Array(7, 9, 10, 5, 8, 4, 2, 1, 6, 3, 7, 9, 10, 5, 8, 4, 2) //系数
      var b = new Array('1', '0', 'X', '9', '8', '7', '6', '5', '4', '3', '2') //校验码对照表
      var id_array = id.split('')
      var sum = 0
      for (var k = 0; k < 17; k++) {
        sum += parseInt(id_array[k]) * parseInt(c[k])
      }
      if (id_array[17].toUpperCase() != b[sum % 11].toUpperCase()) {
        return callback(new Error('身份证校验码不合规'))
      }
      callback()
    }
    return {
      type: 'view', //view   edit
      style: 'right',
      width: '78px',
      options: provinceAndCityData,
      form: {
        realname: '',
        mobile: '',
        avatar: '',
        code: '',
        idcard: '',
        sex: '1',
        native_place: '',
        tmp_native_place: []
      },
      rules: {
        realname: [{ required: true, message: '必需填写', trigger: 'blur' }],
        mobile: [{ required: true, validator: checkMobile, trigger: 'blur' }],
        idcard: [{ validator: checkIdCard, trigger: 'blur' }]
      },

      myHeaders: {
        token: localStorage.getItem('x-auth-token')
      },
      myData: {
        type: 'avatar'
      }
    }
  },
  mounted() {
    this.getUserDetail()
  },
  methods: {
    uploadSuccess(response, file, fileList) {
      this.form.avatar = response.data.file_url
      //更新头像
      this.updateAvatar()
    },
    updateAvatar() {
      this.$axios
        .post('/user/update_profile', {
          avatar: this.form.avatar
        })
        .then(res => {
          if (res.data.code == 200) {
            this.$message.success(res.data.msg)
          } else {
            this.$message.error(res.data.msg)
          }
        })
    },
    getCityText(code) {
      if (code == '') {
        return ''
      }

      let arr = JSON.parse(code)
      return CodeToText[arr[0]] + ' / ' + CodeToText[arr[1]]
    },
    getRole(id) {
      return roleByID(id)
    },
    //更新用户信息
    update(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          this.$axios.post('/user/update_profile', this.form).then(res => {
            if (res.data.code == 200) {
              this.$message.success(res.data.msg)

              this.$store.commit('set_user', this.form)
            } else {
              this.$message.error(res.data.msg)
            }
          })
        }
      })
    },
    //根据部门ID获取部门名
    getDepartmentName(pidArr) {
      var department_name = ''
      this.$axios.post('/index/depts').then(res => {
        if (res.data.code == 200) {
          // console.log(res.data.data.list)
          pidArr.forEach(value => {
            department_name += res.data.data.list[value] + ' '
          })

          this.form.department_name = department_name
          this.$forceUpdate()
        }
      })
    },
    //获取用户信息
    getUserDetail() {
      this.$axios.post('/user/detail').then(res => {
        if (res.data.code == 200) {
          this.form.uid = res.data.data.uid
          this.form.realname = res.data.data.realname
          this.form.mobile = res.data.data.mobile
          this.form.avatar = res.data.data.avatar
          this.form.code = res.data.data.code
          this.form.idcard = res.data.data.idcard
          this.form.native_place = res.data.data.native_place
          this.form.join_date = res.data.data.join_date
          if (res.data.data.native_place != '') {
            this.form.tmp_native_place = JSON.parse(res.data.data.native_place)
          }
          this.form.role_name = this.getRole(res.data.data.role).label
          this.form.department_name = '加载中'
          this.getDepartmentName(res.data.data.department)
        }
      })
    }
  },
  watch: {
    'form.tmp_native_place': function() {
      this.form.native_place = JSON.stringify(this.form.tmp_native_place)
    },
    type() {
      this.style = this.type == 'view' ? 'right' : 'top'
      this.width = this.type == 'view' ? '78px' : '150px'
    }
  }
}
</script>
<style lang="less" scoped>
.main-content {
  /deep/ .el-card__body {
    padding-left: 0;
    padding-right: 0;
  }
}

.p-20 {
  padding-left: 20px;
}
.w100 {
  width: 100%;
}
.menu-box {
  display: flex;
  .left-menu {
    width: 224px;
  }
  .right-main {
    margin: 8px 40px;
    flex: 1;
    .head {
      display: flex;
      margin-bottom: 24px;
      .head-title {
        font-weight: 500;
        font-size: 20px;
        flex: 1;
      }
      .head-btns {
        display: flex;
        align-items: center;
        i.el-icon-check {
          font-size: 20px;
          color: #409eff;
        }
      }
    }
  }
}
.body {
  /deep/ .el-form-item__label {
    padding: 0;
  }
  display: flex;
  .form-main {
    width: 310px;
  }
}

.form-avatar {
  padding-left: 100px;
  .label {
    line-height: 36px;
  }
  .avatar {
    width: 140px;
    height: 140px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    /deep/ .el-icon-user-solid {
      font-size: 70px;
    }
  }
  .btn {
    width: 140px;
    text-align: center;
    padding: 10px 0;
  }
}
</style>